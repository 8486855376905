<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-4">
          <c-card title="훈련평가 상세" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <c-field 
                      :editable="editable"
                      :data="info"
                      type="dept_user" 
                      label="평가자" 
                      name="evaluationUserId" 
                      v-model="info.evaluationUserId" />
                  </div>
                </div>
                <div class="row q-pt-sm">
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <c-textarea
                      :editable="editable"
                      label="평가의견"
                      :rows="26"
                      name="evaluationOpinon"
                      v-model="info.evaluationOpinon">
                    </c-textarea>
                  </div>
                </div>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-8">
          <c-table
            ref="table"
            title="훈련평가항목 목록"
            :columns="grid.columns"
            :data="grid.data"
            noDataLabel="훈련구분에 따른 항목이 나옵니다."
            :editable="editable"
            :usePaging="false"
            :isExcelDown="false"
            :hideBottom="false"
            rowKey="attendUserId"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn
                  v-if="!disabled && editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="info"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="savePlan"
                  @btnCallback="saveCallback" 
                />
              </q-btn-group>
            </template>
            <!-- <template slot="table-button">
              <q-btn-group outline >
                <c-btn v-if="editable && !disabled" :showLoading="false" label="LBLADD" icon="add" @btnClicked="add" />
                <c-btn v-if="editable && !disabled" :showLoading="false" label="LBLREMOVE" icon="remove" @btnClicked="remove" />
              </q-btn-group>
            </template> -->
          </c-table>
        </div>
      </div>
    </q-form>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'traning-assess',
  props: {
    param: {
      type: Object,
      default: () => ({
        sopEmergencyTrainingId: '',
        eapTrainingTypeCd: '',
        plantCd: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      saveUrl: transactionConfig.sop.eap.training.plan.update.url + '/item',
      saveType: 'PUT',
      isSave: false,
      getItemUrl: '',
      getUrl: '',
      info : {
        sopEmergencyTrainingId: '',
        evaluationUserId: '',
        evaluationOpinon: '',
        deptCd: '',
        emergencyTrainingEvaluationModels: [],
      },
      grid: {
        columns: [],
        data: [],
        height: '460px'
      },
      editable: true,
      searchUrl: '',
      disabled: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getItemUrl = selectConfig.sop.eap.training.plan.getItem.url;
      this.getUrl = selectConfig.sop.eap.training.plan.get.url;
      this.getDetail();
      this.setHeader();
    },
    getDetail() {
      if (this.param.sopEmergencyTrainingId) {
        this.$http.url = this.$format(this.getUrl, this.param.sopEmergencyTrainingId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.info.sopEmergencyTrainingId = this.param.sopEmergencyTrainingId;
          this.info.evaluationUserId = _result.data.evaluationUserId;
          this.info.evaluationOpinon = _result.data.evaluationOpinon;
          this.info.deptCd = _result.data.deptCd;
          if (_result.data.trainingCompleteFlag === 'Y') {
            this.disabled = true;
          }
        },);
      }
    },
    setHeader() {
      this.$comm.getComboItems('EAP_TRAINING_POINT_CD', false).then(_result => {
        this.grid.columns = [
          {
            name: 'evaluationItemName',
            field: 'evaluationItemName',
            label: '평가항목',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            setHeader: true,
            name: 'evaluationPointCd',
            field: 'evaluationPointCd',
            label: '점수',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            type: 'select',
            comboItems: _result
          },
          {
            name: 'remark',
            field: 'remark',
            label: 'LBLREMARK',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'text'
          },
        ]
      });
      this.getData();
    },
    getData() {
      this.$http.url = this.getItemUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        sopEmergencyTrainingId: this.param.sopEmergencyTrainingId,
        trainingTypeCd: this.param.eapTrainingTypeCd,
        plantCd: this.param.plantCd,
        useFlag: 'Y',
      }
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        this.info.emergencyTrainingEvaluationModels = _result.data;
      },);
    },
    savePlan() {
      this.saveUrl = transactionConfig.sop.eap.training.plan.update.url + '/item';
      this.saveType = 'PUT';
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.info.chgUserId = this.$store.getters.user.userId
              this.info.emergencyTrainingEvaluationModels = this.grid.data;
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
      this.getData();
    },
  }
};
</script>